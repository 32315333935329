<template>
  <div>
    <div style="position: sticky; top: 0px; z-index: 999">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item class="homebanner">
          <img
            src="http://lycaljdapi.dexian.ren//upload/images/2021/12/8a49aa95-3cc.png"
        /></van-swipe-item>
        <van-swipe-item class="homebanner"
          ><img
            src="http://lycaljdapi.dexian.ren//upload/images/2021/12/7ec78973-720.png"
        /></van-swipe-item>
        <van-swipe-item class="homebanner"
          ><img
            src="http://lycaljdapi.dexian.ren//upload/images/2021/12/c8b1493d-32c.png"
        /></van-swipe-item>
        <!--  <van-swipe-item>4</van-swipe-item> -->
      </van-swipe>
    </div>

    <!-- <van-tabs v-model="active"
      :sticky="true"
      color="#ffc002">
      <van-tab title="点菜"> -->
    <!-- <van-tree-select height="100vw"
          :items="items"
          :main-active-index.sync="activeType">
          <template #content> -->
    <van-sidebar v-model="activeKey" color="#ffc002" class="boxLeft">
      <van-sidebar-item title="全部" @click="onClick('0')"></van-sidebar-item>
      <van-sidebar-item
        :title="item.Name"
        v-for="item in ServiceType"
        :key="item.SId"
        @click="onClick(item.SSId)"
      >
      </van-sidebar-item>
    </van-sidebar>

    <div class="boxRight">
      <!-- <van-list :loading="loading"
        :finished="finished"
        :offset="0"
        @load="onLoad">
        <div class="waterfall">
          <div class="list"
            v-for="item in ServiceList"
            :key="item.SId">
            <img :src="item.ImgUrl"
              onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
              style="width:100%;"
              @click="rockDetail(item.SId)" />
            <div style="width:90%;margin:auto;">
              <div style="font-size: 18px;color: #262626;font-weight:550;"
                class="van-ellipsis">{{item.Name}}</div>
              <div class="money">
                <span>¥{{item.Amount}} <span style="font-size:14px;">{{item.MUnit}}</span></span>
                <van-button icon="plus"
                  size="small"
                  style="border-radius: 12px;"
                  color="#ffc002"
                  type="primary"
                  @click="add(item)">
                </van-button>
              </div>

              <br>
            </div>

          </div>
        </div>
      </van-list> -->
      <van-list
        :loading="loading"
        :finished="finished"
        :offset="0"
        @load="onLoad"
      >
        <div
          v-for="item in ServiceList"
          :key="item.SId"
          style="margin-bottom: 5px"
        >
          <div class="van-card">
            <div class="van-card__header">
              <a class="van-card__thumb">
                <div
                  class="van-image"
                  @click="rockDetail(item.SId)"
                  style="width: 100%; height: 100%"
                >
                  <img
                    :src="item.ImgUrl"
                    onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
                    class="van-image__img"
                    style="object-fit: cover"
                  />
                </div>
              </a>
              <div class="van-card__content">
                <div>
                  <div class="van-card__title van-multi-ellipsis--l2">
                    &nbsp;
                  </div>
                  <div class="van-card__desc van-ellipsis">{{ item.Name }}</div>
                </div>
                <div class="van-card__bottom">
                  <div class="van-card__price">
                    <div>
                      <span
                        class="van-card__price-integer"
                        style="color: red; margin-bottom: 20px"
                        >{{ item.Amount }}{{ item.MUnit }}</span
                      >
                    </div>
                  </div>
                  <div class="van-card__num">
                    <span>0份</span>
                    <van-button
                      icon="plus"
                      size="small"
                      style="border-radius: 12px"
                      color="#ffc002"
                      type="primary"
                      @click="add(item)"
                    >
                    </van-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <!-- <van-list :loading="loading"
        :finished="finished"
        :offset="0"
        @load="onLoad">
        <div style="
                float: left;
                margin: 5px 10px;
                border-bottom: 1px solid #c6c6c6;
              "
          v-for="item in ServiceList"
          :key="item.SId">
          <div @click="rockDetail(item.SId)"
            style="float: left; width: 40%">
            <img :src="item.ImgUrl"
              onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
              style="width:100%;border: 2px solid #ff7e1e;    border-radius: 5px;" />
          </div>
          <div style="
                  float: left;
                  margin-left: 5px;
                  font-size: 14px;
                  color: #646566;
                  width: 40%;
                  padding-left: 5px;

                ">
            <span style="line-height: 30px">
              <span style="font-weight: 600;color: black;">{{ item.Name }}<br /></span>
              <span style="color: red;">￥{{ item.Amount }}{{ item.MUnit }}<br /></span>

              {{ item.Charge }}
            </span>
          </div>
          <div style="right:0px;bottom:0px;width: 15%;">
            <van-button icon="plus"
              size="small"
              round
              color="#ffc002"
              type="primary"
              @click="add(item)">
            </van-button>
          </div>

        </div>
      </van-list> -->
      <!-- </div> -->

      <div class="hidden" v-if="hidden">没有更多了~</div>
    </div>
    <!-- </van-tab> -->
    <!-- <van-tab title="评价">评价</van-tab> -->
    <!-- </van-tabs> -->

    <van-action-sheet v-model="show" title="已选商品">
      <div class="content">
        <div>
          <div v-for="item in orderList" :key="item.SId">
            <div class="orderYx">
              <div style="float: left; width: 25%">
                <img
                  :src="item.SImgUrl"
                  onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
                  class="van-image__img"
                  style="object-fit: cover"
                />
              </div>
              <div class="orderRight">
                <span style="line-height: 30px">
                  <span
                    style="font-size: 18px; font-weight: 600"
                    class="van-ellipsis--l1"
                    >{{ item.SName }}<br
                  /></span>
                  <span style="color: red"
                    ><span style="font-size: 16px">￥{{ item.SPrice }}</span>
                    {{ item.SMUnit }}<br
                  /></span>
                  {{ item.SNum }}份 总价：￥{{ item.SAmount }}</span
                >
              </div>
              <div
                style="
                  padding: 10px;
                  margin-top: -30px;
                  width: 26%;
                  float: right;
                "
              >
                <van-stepper
                  v-model="item.SNum"
                  theme="round"
                  min="0"
                  style="right: 20px; top: 110px"
                  button-size="22"
                  disable-input
                  @plus="changeAdd(item)"
                  @minus="changeDelet(item)"
                  @change="change"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%;"
              color="black"
              @click="placeOrder()">
              下单
            </van-button>
          </div>
        </div> -->
        <!-- <img src="http://lycaljdapi.dexian.ren//upload/images/2021/12/5c19b55e-c98.png" /> -->

        <!-- <div style="position: absolute;top: 10px;left: 100px;">下单</div> -->

        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button
              round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="orderRen()"
            >
              下 单
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <!-- <div class="anniu"
      style="text-align: center;margin:10px 0;">
      <van-button round
        type="primary"
        size="normal"
        style="font-size: 16px; width: 95%"
        color="black"
        @click="showPopup()">
        <img src="http://lycaljdapi.dexian.ren//upload/images/2021/12/5c19b55e-c98.png" />

        <div style="position: absolute;top: 10px;left: 100px;">$31234</div>
      </van-button>
    </div> -->
    <div class="anniu" v-if="showPopupShow" @click="showPopup()">
      <img
        src="http://lycaljdapi.dexian.ren//upload/images/2021/12/5c19b55e-c98.png"
      />
    </div>
    <van-action-sheet v-model="orderRenshow" title="收货地址" @close="close()">
      <div class="content">
        <van-radio-group
          v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="
            margin: 10px;
            padding: 15px 10px;
            background: #f6f7f8;
            border-radius: 5px;
          "
        >
          <van-radio
            :name="item.BindId"
            checked-color="#ffc002"
            style="padding: 20rpx"
          >
            <div
              style="
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10rpx;
                margin: 5px;
              "
            >
              {{ item.Name }}
              <span style="font-weight: 500">{{ item.Mobile }}</span>
              <span style="padding-left: 20px" @click="updata(item)">
                <van-icon name="edit"
              /></span>
              <span style="padding-left: 20px" @click="addOldMan()">
                <van-icon name="add-o"
              /></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px">{{ item.Addr }}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button
              round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()"
            >
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet
      v-model="orderRenAddshow"
      title="添加配送老人信息"
      @close="close()"
    >
      <div class="content">
        <van-field
          v-model="oldmanForm.Name"
          class="shuru"
          name="oldmanForm.Name"
          label="收件人"
          placeholder="请输入收件人"
        />
        <van-field
          v-model="oldmanForm.Mobile"
          class="shuru"
          name="oldmanForm.Mobile"
          label="联系电话"
          placeholder="请输入老人联系电话"
        />
        <van-field
          v-model="oldmanForm.Addr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.Addr"
          label="居住地址"
          placeholder="请输入老人现居住地址"
        />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round block type="info" color="black" @click="submit()"
            >保存</van-button
          >
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {
  WeGetServiceList,
  WeGetSerSortOfManList,
  WeGetProServicetPage,
  WeGenerateOrder,
  WeGetOrderServiceList,
  WeSaveOrderService,
  WePlaceOrder,
  WeDeleteOrderService,
  WeGetMyBindInfoPage,
  WeSaveBindInfo,
} from "@/api/ylOrder.js";
import {
  setOpenId,
  getOpenId,
  setOrderId,
  getOrderId,
  removeOrderId,
} from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
export default {
  data() {
    return {
      ServiceList: [],
      ServiceType: [],
      orderList: [],
      active: 0,
      show: false,
      showPopupShow: false,
      orderRenAddshow: false,
      activeKey: 0,
      items: [{ text: "分组 1" }, { text: "分组 2" }],
      value: 0,
      orderId: "",
      sum: "",
      count: 1,
      sumid: "",
      addOrder: "",
      OSId: "",
      soId: "",
      orderRenshow: false,
      oldManList: "",
      MyBindInfoList: [],
      oldmanForm: {
        Name: "",
        Mobile: "",
        Addr: "",
        BindId: undefined,
      },
      listfrom: {
        page: 0,
        limit: 10,
      },
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      ssId: 0,
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getServiceList();
    // this.orderType('0')
    // this.getList();
    // 生成订单
    this.createOrder();
    console.log(this.$route.params);
  },
  methods: {
    // 生成订单
    createOrder() {
      WeGenerateOrder({
        OType: 1,
        OSerKind: this.$route.params.Id,
        SOId: this.$route.params.SOId,
        OrgCode: this.$route.params.OrgCode,
        OpenID: getOpenId(),
      }).then((res) => {
        // this.ServiceList = res.data.data;
        if (res.data.code == "0") {
          this.orderId = res.data.data;
          this.getOrderServiceList();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 服务项目分类列表
    getServiceList: function (row) {
      if (this.$route.params.SOId != undefined) {
        this.soId = this.$route.params.SOId;
      }
      WeGetSerSortOfManList({
        serKind: this.$route.params.Id,
        soId: this.soId,
        openID: getOpenId(),
      }).then((res) => {
        this.ServiceType = res.data.data;
      });
    },
    rockDetail: function (row) {
      this.$router.push(
        "/ylOrder/ylOrder/currentOrder/servicetOfMan/" +
          row +
          "/" +
          this.$route.params.OrgCode
      );
    },
    close: function () {
      // console.log(123123);
      this.oldManList = "";
      this.oldmanForm.OMIdCard = "";
      this.oldmanForm.OMMobile = "";
      this.oldmanForm.OMAddr = "";
    },
    // 下单人
    orderRen: function (row) {
      this.orderRenshow = true;
      this.getMyBindInfoPage();
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({
        openID: getOpenId(),
        page: "1",
        limit: "999",
      }).then((res) => {
        if (res.data.code == "0") {
          if (res.data.data.length != "0") {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            // Toast.fail('未绑定老人信息');
            this.MyBindInfoList = [];
            this.orderRenshow = false;
            this.orderRenAddshow = true;
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 生成订单
    add: function (row) {
      console.log(row);
      if (this.sumid != row.SSId) {
        this.count = 1;
        this.sum = 1;
      }
      if (this.orderId == "") {
        this.createOrder();
      } else {
        this.saveOrderService(row.SId);
        // WeGenerateOrder({
        //   OType: 1,
        //   OSerKind: this.$route.params.Id,
        //   SOId: this.$route.params.SOId,
        //   OpenID: getOpenId(),
        // }).then((res) => {
        //   // this.ServiceList = res.data.data;
        //   if (res.data.code == "0") {
        //     this.sumid = row.SSId
        //     setOrderId(res.data.data);
        //     this.sum = this.count++;
        //     this.saveOrderService(row.SId);
        //   } else {
        //     Toast.fail(res.data.msg);
        //   }
        // });
      }
    },
    //保存订单
    saveOrderService: function (row) {
      WeSaveOrderService({
        SId: row,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: this.sum,
      }).then((res) => {
        if (res.data.code == "0") {
          this.showPopupShow = true;
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    showPopup: function (row) {
      console.log(row);
      this.show = true;
      this.getOrderServiceList();
    },
    onLoad() {
      this.listfrom.page = this.listfrom.page + 1;
      this.loading = true;
      this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.ServiceList = [];
      this.ssId = e;
      this.listfrom.page = 0;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    // 服务项目类型
    getList: function () {
      this.listfrom.ssId = this.ssId;
      this.listfrom.openID = getOpenId();
      this.listfrom.serKind = this.$route.params.Id;
      this.listfrom.soId = this.$route.params.SOId;
      this.hidden = false;
      setTimeout(() => {
        WeGetProServicetPage(this.listfrom).then((res) => {
          // this.ServiceList = res.data.data;
          let rows = res.data.data; //请求返回当页的列表
          console.log(rows);
          // 加载状态结束
          this.loading = false;

          if (res.data.code == 0) {
            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              this.hidden = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.ServiceList = this.ServiceList.concat(rows);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.ServiceList.length >= res.data.count) {
              this.finished = true;
              this.hidden = true;
            }
          }
        });
      }, 500);
    },
    //已下订单列表
    getOrderServiceList: function () {
      WeGetOrderServiceList({
        openID: getOpenId(),
        orderId: this.orderId,
      }).then((res) => {
        if (res.data.code == "0") {
          if (res.data.data.length != "0") {
            this.orderList = res.data.data;
            this.showPopupShow = true;
          } else {
            this.showPopupShow = false;
          }
        } else {
          // Toast.fail(res.data.msg);
        }
        // this.addOrder = res.data.data[0].OrderId
      });
    },
    //下单
    placeOrder: function () {
      if (this.oldManList == "") {
        Toast.fail("请选择需要配送的老人信息");
      } else {
        WePlaceOrder({
          OpenID: getOpenId(),
          OrderId: this.orderId,
          BindId: this.oldManList,
        }).then((res) => {
          if (res.data.code == "0") {
            Toast.success("下单成功");
            removeOrderId();
            this.count = "";
            this.sum = "";
            this.sumid = "";
            this.orderId = "";
            this.orderList = [];
            this.show = false;
            this.showPopupShow = false;
            this.orderRenshow = false;
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }
    },
    //添加数量
    changeAdd: function (row) {
      // this.OrderId = row.OrderId
      this.SId = row.SId;
    },
    //删除数量
    changeDelet: function (row) {
      // this.OrderId = row.OrderId
      this.SId = row.SId;
      this.OSId = row.OSId;
    },
    //数量
    change: function (value) {
      console.log(value);
      if (value == "0") {
        this.deleteOrderService();
      } else {
        this.changeOrderService(value);
      }
    },
    // 添加删除后保存
    changeOrderService: function (row) {
      WeSaveOrderService({
        SId: this.SId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: row,
      }).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.changeOrderServiceList();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 删除订单服务项目
    deleteOrderService: function () {
      WeDeleteOrderService({
        OSId: this.OSId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.fail("已删除");
          this.changeOrderServiceList();
          this.showPopupShow = false;
        }
      });
    },
    changeOrderServiceList: function () {
      WeGetOrderServiceList({
        openID: getOpenId(),
        orderId: this.orderId,
      }).then((res) => {
        if (res.data.data.length != "0") {
          this.orderList = res.data.data;
          // this.addOrder = res.data.data[0].OrderId
        } else {
          this.show = false;
          this.showPopupShow = false;
        }
      });
    },
    // 添加老人信息
    submit() {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // if (!reg.test(this.oldmanForm.OMIdCard)) {
      //   Toast.fail("身份证号码不正确");
      // }
      if (this.oldmanForm.Name == "") {
        Toast.fail("请输入收件人");
      } else if (this.oldmanForm.Mobile == "") {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.Addr == "") {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo({
          OpenID: getOpenId(),
          Name: this.oldmanForm.Name,
          Mobile: this.oldmanForm.Mobile,
          Addr: this.oldmanForm.Addr,
          BindId: this.oldmanForm.BindId,
        }).then((res) => {
          if (res.data.code == "0") {
            Toast.success("绑定成功");
            this.orderRenAddshow = false;
            this.orderRenshow = true;
            this.oldmanForm.OMIdCard = "";
            this.oldmanForm.OMMobile = "";
            this.oldmanForm.OMAddr = "";
            this.oldmanForm.BindId = undefined;
            this.getMyBindInfoPage();
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }
    },
    addOldMan: function () {
      this.orderRenAddshow = true;
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true;
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  },
};
</script>

<style scoped>
/* .van-button__content {
  float: left !important;
} */
.van-button__text {
  display: inline-grid !important;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 70px !important;
}
.van-card__desc {
  font-weight: 600;
  color: black;
  font-size: 16px;
  margin-top: 15px;
}
.boxLeft {
  width: 25%;
  float: left;
  position: fixed;
  overflow-y: scroll;
  height: 87%;
}
.boxRight {
  margin: 5px auto;
  display: flow-root;
  margin-bottom: 60px;
  float: right;
  width: 75%;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.anniu {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #f7f8fa;
  padding: 8px;
  width: 60px;
  height: 60px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.waterfall {
  /* width: 95%; */
  margin: 5px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 10px 15px 10px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.van-card {
  width: 95%;
  margin: auto;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  border-radius: 5px;
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.money {
  color: #f2270c;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}
.tagClass {
  margin: 3px 3px 3px 0;
  padding: 2px;
  color: #fe5b47;
  border: 1px solid #ffa296;
  font-size: 14px;
  border-radius: 3px;
}
/deep/.van-stepper--round .van-stepper__plus {
  background-color: #ffc002 !important;
}
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #ffc002 !important;
  border: 1px solid #ffc002 !important;
  color: #fff !important;
}
.van-sidebar-item--select::before {
  background-color: #ffc002 !important;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
}
.orderYx {
  float: left;
  margin: 5px 10px;
  border-bottom: 1px solid #c6c6c6;
  width: 95%;
}
.orderRight {
  float: left;
  margin-left: 5px;
  font-size: 14px;
  color: #646566;
  width: 70%;
}
</style>